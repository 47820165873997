import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const dataAttr = 'parentColumn';
    const $iframes = $('.responsive-iframe');
    if (!$iframes.length) {
        return;
    }

    $iframes.each((i, e) => {
        const $this = $(e);
        $this.data(dataAttr, $this.parents('.content-column__inner'));
    });

    function resize() {
        $iframes.removeClass('responsive-iframe--reset').each((i, container) => {
            const $container = $(container);
            const $column = $container.data(dataAttr);
            $container.css('padding-top', 0);
            const columnHeight = $column.innerHeight();
            const contentHeight = $column
                .children()
                .map((ii, e) => $(e).outerHeight(true))
                .get()
                .reduce((a, b) => a + b, 0);
            const iframeHeight = columnHeight - contentHeight;
            $container.css('padding-top', iframeHeight > 100 ? `${iframeHeight}px` : '');
        });
    }

    addAction('accordion/toggleEnd', resize);
    addAction(LAYOUT, resize);
});
